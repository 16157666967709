import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  }, {
    path: '/about',
    name: 'About',
    component: () => import('@/views/Home.vue')
  }, {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  }, {
    path: '/registro',
    name: 'RegistroForm',
    component: () => import('@/views/registro/RegistroForm.vue'),
  },
  //esta vista es para registrar los antecedentes del paciente
  {
    path: '/antecedentesmedicos',
    name: 'PacienteNuevoAntecedentes',
    component: () => import('@/views/paciente/PacienteNuevoAntecedentes.vue'),
  }, {
    path: '/cita',
    name: 'CitasForm',
    component: () => import('@/views/citas/CitasForm.vue'),
  }, {
    path: '/confirmaciondecita/:idCit?',
    name: 'CitasConfirmacion',
    component: () => import('@/views/citas/CitasConfirmacion.vue'),
  }, {
    path: '/paciente',
    name: 'PacienteForm',
    component: () => import('@/views/paciente/PacienteForm.vue'),
  },
  //encuesta de satifsaccion
  {
    path: '/encuesta',
    name: 'EncuestaForm',
    component: () => import('@/views/encuesta/EncuestaForm.vue')
  }, {
    path: '/nosotros',
    name: 'NosotrosView',
    component: () => import('@/views/nosotros/NosotrosView.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
