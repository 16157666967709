import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { DateTime } from "luxon";

import VueResource from "vue-resource";
import VueMask from "v-mask";
import VueSession from "vue-session";
import VuePageTransition from "vue-page-transition";

Vue.use(VueSession, {
  persist: true,
});
Vue.use(VueResource);
Vue.use(VueMask);
Vue.use(VuePageTransition);

const raiz = 'https://api.dentalmaster.com.mx/clientes/';

Vue.prototype.$fotos = raiz + "uploads/imagenes/";
Vue.prototype.$raiz = raiz;
Vue.http.options.root = raiz;

Vue.http.options.emulateJSON = true;

Vue.config.productionTip = false;

Vue.prototype.$primary = "#385573";
Vue.prototype.$secondary = "#7AB1FF";
Vue.prototype.$accent = "#E7D7C1";
Vue.prototype.$error = "#BA274A";
Vue.prototype.$background = "#fff";
Vue.prototype.$headers = {
  headers: {
    Token: null,
    "Cache-Control": "no-cache",
  },
};
const $llenarCampo = "Este campo es obligatorio";
Vue.prototype.$llenarCampo = $llenarCampo;
Vue.prototype.$lleneCampos =
  "Es necesario llenes los campos indicados con * y en color rojo";
Vue.prototype.$noInternet = "Verifique su conexión a internet";
Vue.prototype.$noData = "No hay datos disponibles";

Vue.prototype.$nF = (n) => {
  return Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(n);
};

Vue.prototype.$Datetime = DateTime;

new Vue({
  router,
  vuetify,
  // LogRocket,
  render: (h) => h(App),
}).$mount("#app");
