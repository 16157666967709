var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title",domProps:{"textContent":_vm._s('Dental Master')}})],1)],1),_c('v-divider'),_c('v-list-item-group',{attrs:{"active-class":"accent","color":"white"}},[_c('ListItemMenu',{attrs:{"icon":"home","title":"Inicio","to":"/"}}),_c('ListItemMenu',{attrs:{"icon":"today","title":"Citas","to":"/citas"}}),_c('ListGroupMenu',{attrs:{"icon":"settings","title":"Configuraciones","items":[
      {text:'Misión',to:'/configuracion/mision'},
      {text:'Visión',to:'/configuracion/vision'},
      {text:'Aviso de privacidad',to:'/configuracion/avisodeprivacidad'},
      {text:'Términos y condiciones',to:'/configuracion/terminosycondiciones'},
      {text:'Consentimiento informado',to:'/configuracion/consentimientoinformado'},
      ]}}),_c('ListItemMenu',{attrs:{"icon":"list_alt","title":"Materiales","to":"/materiales"}}),_c('ListGroupMenu',{attrs:{"icon":"loyalty","title":"Servicios","items":[
      {text:'Servicios registrados',to:'/servicios'},
      {text:'Registrar servicio',to:'/servicio'},
      ]}}),_c('ListGroupMenu',{attrs:{"icon":"supervisor_account","title":"Pacientes","items":[
      {text:'Pacientes registrados',to:'/pacientes'},
      {text:'Registrar paciente',to:'/paciente'},
      ]}}),_c('ListItemMenu',{attrs:{"icon":"local_shipping","title":"Proveedores","to":"/proveedores"}}),_c('ListItemMenu',{attrs:{"icon":"supervised_user_circle","title":"Usuarios","to":"/usuarios"}}),_c('v-divider',{staticClass:"mt8 mb8"}),_c('ListItemMenu',{attrs:{"icon":"exit_to_app","title":"Cerrar sesión","to":"/login"},on:{"click":function($event){return this.$session.destroy()}}}),_c('v-list-item',{attrs:{"dense":"","disabled":""}},[_c('v-list-item-content',[_c('small',[_vm._v("0.1.24")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }