<template>
  <v-app
    style="min-width: 100%; height: 100vh;"
    :class="showAppClasses"
  >
    <!-- <AppBar v-if="showAppBar" /> -->
    <v-main>
      <vue-page-transition name="fade">
        <router-view
          :class="showClasses"
          @msg="_notify"
          @httpError="_resFail"
          @classes="_classes"
          @visible="_visible"
          @appClasses="_appClasses"
        />
      </vue-page-transition>
    </v-main>
    <v-snackbar
      v-model="snackbar.show"
      bottom="bottom"
      :timeout="4500"
      :dark="showAppClasses"
    >
      <v-btn
        text
        @click="snackbar.show = false"
        icon
        class="dark-icon"
      >
        <v-icon v-text="'close'" />
      </v-btn>
      {{ snackbar.text }}
    </v-snackbar>
  </v-app>
</template>

<script>
import AppBar from "@/components/appbar/AppBar";
export default {
  name: "App",
  components: { AppBar },
  methods: {
    _notify(a) {
      this.snackbar.text = a;
      this.snackbar.show = true;
    },
    _resFail(a) {
      if (a.status === 401) {
        this.$router.push("/login");
      } else if (a.status === 400) {
        this._notify(a.body.error);
      } else if (a.body.error) {
        this._notify(a.body.error);
      } else {
        this._notify(this.$noInternet);
      }
    },
    _visible(a) {
      this.showAppBar = a;
    },
    _classes(a) {
      this.showClasses = a;
    },
    _appClasses(a) {
      this.showAppClasses = a;
    },
  },
  created() {
    this.$headers.headers.Token =
      this.$session.exists() && this.$session.get("token")
        ? this.$session.get("token")
        : null;
  },
  data() {
    return {
      showClasses: "",
      snackbar: {
        show: false,
        text: "",
      },
      showAppBar: false,
      showAppClasses: "",
    };
  },
  watch: {
    $route(a, b) {
      const ruta = this.$route.name;
      if (ruta != "Login" && ruta != "RegistroForm" && this.$session.exists()) {
        const nuevo = this.$session.get("nuevo");
        if (nuevo === "si" && ruta != "AntecedentesNuevos")
          this.$router.push("/antecedentesmedicos");
      }
      // if(this.$session.exists()){

      // }
    },
  },
};
</script>
<style lang="scss">
@import "styles/sass/_app";
.isShowingLogin {
  // @include mobile {
  background: #385573 !important;
  height: 100%;
  min-height: 100vh;
  // }
}
</style>