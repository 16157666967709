import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#e20338',//process.env.VUE_APP_COLOR_PRIMARY,
        secondary: process.env.VUE_APP_COLOR_SECONDARY,
        accent: process.env.VUE_APP_COLOR_ACCENT,
        error: process.env.VUE_APP_COLOR_ERROR,
        background: process.env.VUE_APP_COLOR_BACKGROUND,
      },
      dark: {
        primary: process.env.VUE_APP_COLOR_PRIMARY,
      }
    },
  },
  icons: {
    iconfont: 'md',
  },
  lang: {
    locales: { es },
    current: 'es'
  }
});
